import React from 'react';
import FooterTwo from '../../Footer/FooterTwo';
import HeaderTwo from '../../Header/HeaderTwo';
import BannerTwo from '../BannerTwo/BannerTwo';
import AboutTwo from '../AboutTwo/AboutTwo';
import ServicesTwo from '../ServicesTwo/ServicesTwo';
import Video from '../Video/Video';
import Benefit from '../Benefit/Benefit';
import PortfoliosTwo from '../PortfoliosTwo/PortfoliosTwo';
import TestimonialTwo from '../TestimonialTwo/TestimonialTwo';
import TextAnimation from '../TextAnimation/TextAnimation';
import Team from '../Team/Team';
import FunfactTwo from '../FunfactTwo/FunfactTwo';
import AboutThree from '../AboutThree/AboutThree';
import Company from '../Company/Company';
import BlogTwo from '../BlogTwo/BlogTwo';
import { pageTitle } from '../../PageTitle';
import Services from '../../Services/Services';
import Plans from '../../Services/plans';
import Contact from '../../Contact/Contact';


const HomeTwo = () => {

    pageTitle('Pole Dance Miami');
    return (
        <>
          
            
            <HeaderTwo></HeaderTwo>
            <BannerTwo></BannerTwo>
            <AboutTwo></AboutTwo>
            <Services isSection={true}></Services>
            <Plans isSection={true}></Plans>
            {/* <Benefit></Benefit> */}
            {/* <PortfoliosTwo></PortfoliosTwo> */}
            <Team></Team>
            <TestimonialTwo></TestimonialTwo>
            {/* <TextAnimation></TextAnimation> */}
          
            {/* <FunfactTwo></FunfactTwo> */}
            {/* <AboutThree></AboutThree> */}
            <Contact isSection={true}></Contact>
            {/* <BlogTwo></BlogTwo> */}

            <FooterTwo></FooterTwo>
        </>
    );
};

export default HomeTwo;